import React, { useEffect } from 'react';
import { AutogramVideoType } from 'types';
import { useIntroOutroUploadIntegration } from '../integrations';
import useMediaUploadIntegration from '../integrations/useMediaUploadIntegration';
import useStaticTextIntegration from '../integrations/useStaticTextIntegration';
import { getTemplateType } from '../state/config-utils';
import { DEFAULT_FEATURES } from '../state/constants';
import { useNavigation } from '../state/fsm/navigation-machine';
import { getStateContent } from '../state/state-utils';
import useEditorStateReducer from '../state/useEditorStateReducer';
import useTemplateDispatch from '../state/useTemplateDispatch';
import { VideoTemplateEditorFeatures } from '../types';
import { OnStateChange } from '../types/core';
import { VideoTemplateEditorIntegrations } from '../types/integrations';
import { VideoTemplateState } from '../types/state';
import { CanvasSizeProvider } from './CanvasSizeContext';
import { NavigationProvider } from './NavigationContext';
import { VideoEditorDispatchProvider } from './VideoEditorDispatchContext';
import { VideoEditorStateProvider } from './VideoEditorStateContext';
import { VideoTemplateDispatchProvider } from './VideoTemplateDispatchContext';
import { VideoTemplateStateProvider } from './VideoTemplateStateContext';

export interface VideoTemplateEditorContextProviderProps {
  addTextPlaceholder?: string;
  audioClipDurationSec?: number;
  children?: React.ReactNode;
  disabled?: boolean;
  features?: VideoTemplateEditorFeatures;
  integrations?: VideoTemplateEditorIntegrations;
  isFullEpisode?: boolean;
  loading?: boolean;
  onChange?: OnStateChange;
  videoType?: AutogramVideoType;
  state: VideoTemplateState;
  onCtaClick?: (isTemplateDirty?: boolean) => void;
}

const VideoTemplateEditorContextProvider: React.FC<VideoTemplateEditorContextProviderProps> = ({
  addTextPlaceholder,
  audioClipDurationSec,
  children,
  disabled,
  features = DEFAULT_FEATURES,
  integrations,
  isFullEpisode,
  loading,
  onChange,
  state,
}) => {
  const { canvas, container, template } = getStateContent(state);
  const defaultIntegrations = [
    useMediaUploadIntegration({ priority: 0 }),
    useStaticTextIntegration({ placeholder: addTextPlaceholder, priority: 2 }),
    useIntroOutroUploadIntegration({ priority: 3 }),
  ];

  const templateType = getTemplateType(template);

  const machine = useNavigation({
    pendingChildView: undefined,
    id: undefined,
    templateType,
  });
  const templateDispatch = useTemplateDispatch({ onChange });
  const [editorState, editorDispatch] = useEditorStateReducer();

  const [, , service] = machine;

  useEffect(() => {
    const subscription = service.subscribe(({ event }) => {
      templateDispatch(event);
      editorDispatch(event);
    });
    return subscription.unsubscribe;
  }, [editorDispatch, service, templateDispatch]);

  return (
    <VideoEditorStateProvider
      integrations={integrations || defaultIntegrations}
      state={editorState}
      {...{
        addTextPlaceholder,
        audioClipDurationSec,
        disabled,
        features,
        isFullEpisode,
        loading,
      }}
    >
      <VideoEditorDispatchProvider dispatch={editorDispatch}>
        <CanvasSizeProvider canvas={canvas} container={container}>
          <NavigationProvider value={machine}>
            <VideoTemplateDispatchProvider value={templateDispatch}>
              <VideoTemplateStateProvider value={state}>
                {children}
              </VideoTemplateStateProvider>
            </VideoTemplateDispatchProvider>
          </NavigationProvider>
        </CanvasSizeProvider>
      </VideoEditorDispatchProvider>
    </VideoEditorStateProvider>
  );
};

export default VideoTemplateEditorContextProvider;
