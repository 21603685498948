import { Music } from '@sparemin/blockhead';
import { CaptionsAnimationName } from '@sparemin/text-effects';
import React from 'react';

import { hexToRgba } from 'utils/color';
import { SM_BLUE } from 'utils/constants';
import { AnimationModule } from '../../types';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import { checkIsKaraokeAnimation } from '../../utils';
import AnimationModuleButton from '../AnimationModuleButton';
import KaraokeAnimationControls from './KaraokeAnimationControls';

export interface KaraokeAnimationModuleProps {}

const DEFAULT_ACTIVE_KARAOKE_COLOR = hexToRgba(SM_BLUE);
const DEFAULT_KARAOKE_ANIMATION_NAME: CaptionsAnimationName = 'activeWordColor';

const KaraokeAnimation: React.FC<KaraokeAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnOnAdvancedAnimation,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="Karaoke"
      icon={<Music width={50} height={50} />}
      isSelected={
        selectedAdvancedAnimation?.enabled &&
        checkIsKaraokeAnimation(selectedAdvancedAnimation)
      }
      onPress={() => {
        onTurnOnAdvancedAnimation(
          DEFAULT_KARAOKE_ANIMATION_NAME,
          {
            wordEmphasisColor: DEFAULT_ACTIVE_KARAOKE_COLOR,
          },
          false,
        );
      }}
    />
  );
};

const karaokeAnimationModule: AnimationModule = {
  key: 'karaoke',
  name: 'Karaoke',
  control: KaraokeAnimation,
  contents: <KaraokeAnimationControls />,
};

export default karaokeAnimationModule;
