import { useSelector } from 'react-redux';
import { isFreeOrEduSelector } from 'redux/modules/pricing';

interface WizardConfig {
  languageStepVisible: boolean;
  showEddyProjectCreationSelector: boolean;
}

interface UseEddyTranscriptionCheck {
  audiogramWizard: WizardConfig;
  episodeWizard: WizardConfig;
  downloadPagePopperVisible: boolean;
}

const useEddyPromoCheck = (): UseEddyTranscriptionCheck => {
  const isFreeOrEdu = useSelector(isFreeOrEduSelector);

  return {
    audiogramWizard: {
      languageStepVisible: !isFreeOrEdu,
      showEddyProjectCreationSelector: !isFreeOrEdu,
    },
    // As there is no other way for setting the language at this wizard, the language step will
    // be true regardless of the user tier.
    episodeWizard: {
      languageStepVisible: true,
      showEddyProjectCreationSelector: !isFreeOrEdu,
    },
    downloadPagePopperVisible: !isFreeOrEdu,
  };
};

export default useEddyPromoCheck;
