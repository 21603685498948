import cn from 'classnames';
import { Map } from 'immutable';
import React, { useCallback } from 'react';
import _ from 'underscore';

import VideoTemplateEditor, {
  CustomizeStepBrandingOptions,
  TemplateAction,
  VideoTemplateEditorProps,
  VideoTemplateState,
} from 'components/VideoTemplateEditor';
import { TranscriptionFormValue } from 'containers/TranscriptionForm';
import useCustomFontsLoader from 'hooks/useCustomFontsLoader';
import { AspectRatioName, Omit } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';

import TemplateStep, { TemplateStepProps } from '../TemplateStep';
import { RenderCustomizeCtaProps } from './types';
import useCustomizeStepState, {
  UseCustomizeStepStateConfig,
} from './useCustomizeStepState';
import { block } from './utils';

type PickedEditorProps = Pick<VideoTemplateEditorProps, 'ctaLabel'>;
type PickedTemplateStepProps = Pick<
  TemplateStepProps,
  | 'compatibilityTypes'
  | 'isProjectEditable'
  | 'podcastId'
  | 'templateTypes'
  | 'transcriptionEnabled'
>;
export type CustomizeStepView = 'edit' | 'templates';

export interface CustomizeStepProps
  extends PickedEditorProps,
    PickedTemplateStepProps,
    Omit<UseCustomizeStepStateConfig, 'templateId'> {
  addTextPlaceholder?: string;
  brandingOptions?: CustomizeStepBrandingOptions;
  className?: string;
  onChangeView?: (view: CustomizeStepView) => void;
  onSelectTemplate?: (id: string) => void;
  onSelectAspectRatio?: (newAspectRatio: AspectRatioName) => void;
  renderCta?: (props: RenderCustomizeCtaProps) => JSX.Element;
  templateId: string;
  podcastId?: string;
  episodeId?: string;
  view: CustomizeStepView;
  isLoading?: boolean;
  templateStepAspectRatioName?: AspectRatioName;
  audioClipDurationSec?: number;
  transcription?: TranscriptionFormValue;
}

const CustomizeStep: React.FC<CustomizeStepProps> = ({
  transcription,
  addTextPlaceholder,
  brandingOptions,
  className,
  compatibilityTypes,
  ctaLabel,
  defaults,
  features: baseFeatures,
  isFullEpisode,
  isProjectEditable,
  lastUsedStyle = Map(),
  onChangeView,
  onError,
  onSelectTemplate,
  onSelectAspectRatio,
  onStyleChange = _.noop,
  onSubmit: onSubmitProp = _.noop,
  podcastId,
  episodeId,
  renderCta,
  templateId,
  templateStepAspectRatioName,
  templateTypes,
  transcriptionEnabled,
  view,
  isLoading,
  audioClipDurationSec,
}) => {
  const {
    exportEditorState,
    features,
    imageProcessorStatus,
    integrations,
    onChange,
    onSubmit,
    state,
  } = useCustomizeStepState({
    podcastId,
    episodeId,
    defaults,
    isFullEpisode,
    lastUsedStyle,
    onError,
    onStyleChange,
    onTemplatesClick: () => onChangeView('templates'),
    templateId,
    onSubmit: onSubmitProp,
    features: baseFeatures,
    transcription,
  });

  // preloads the fonts for getting the font styles when mounting the customize step
  useCustomFontsLoader();

  const handleRenderCta: VideoTemplateEditorProps['renderCta'] = useCallback(
    props => {
      return renderCta({
        ...props,
        exportEditorState,
        disabled:
          props.disabled || imageProcessorStatus === 'processing' || isLoading,
      });
    },
    [exportEditorState, imageProcessorStatus, isLoading, renderCta],
  );

  const handleBackClick = useCallback(() => {
    onChangeView('edit');
  }, [onChangeView]);

  const handleTemplateSelect = useCallback(
    id => {
      onSelectTemplate(id);
      handleBackClick();
    },
    [handleBackClick, onSelectTemplate],
  );

  const handleChange = useCallback(
    (
      stateUpdater: (prevState: VideoTemplateState) => VideoTemplateState,
      action: TemplateAction,
    ) => {
      onChange(stateUpdater, action);
    },
    [onChange],
  );

  return view === 'edit' ? (
    <div className={cn(block(), className)}>
      {state && (
        <VideoTemplateEditor
          {...{
            addTextPlaceholder,
            ctaLabel,
            features,
            audioClipDurationSec,
            state,
            transcription,
          }}
          brandingOptions={brandingOptions}
          className={block('editor')}
          isFullEpisode={isFullEpisode}
          loading={imageProcessorStatus === 'processing'}
          onCtaClick={onSubmit}
          renderCta={renderCta && handleRenderCta}
          onChange={handleChange}
        />
      )}
    </div>
  ) : (
    <TemplateStep
      // FIXME - default values aren't generally used as the actual value on every
      // render.  this might cause unexpected results.  Also, the template step should
      // have its own aspect ratio value and shouldn't necessarily be tied to
      // defaults.aspectRatio, which describes the current aspect ratio of the video
      // being customized in the UCS
      aspectRatioName={
        templateStepAspectRatioName || getAspectRatioName(defaults.aspectRatio)
      }
      onChangeAspectRatio={onSelectAspectRatio}
      onSelect={handleTemplateSelect}
      {...{
        compatibilityTypes,
        integrations,
        isProjectEditable,
        podcastId,
        templateTypes,
        transcriptionEnabled,
      }}
    />
  );
};

export default CustomizeStep;
