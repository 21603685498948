import React from 'react';
import { throttle } from 'underscore';
import useFontLoadedListener from 'hooks/useFontLoadedListener';

import { TextOverlayResizeObserver } from '../../hooks/useTextOverlayResizeDelta';
import { EditorDataV2, Size } from '../../types';
import { BoxSizeFitChecker, BoxSizeFitCheckerConfig } from './types';
import {
  getBoxMaxLines,
  shouldDisableSubmitButton,
  shouldShowRechunkAlert,
} from './utils';

interface UseBoxSizeFitCheckerConfig {
  baseTextValue: string;
  canvasSize: Size;
  draftEditorData: EditorDataV2;
  extensionConfig: BoxSizeFitCheckerConfig;
  initialEditorData: EditorDataV2;
}

type UseBoxSizeFitChecker = BoxSizeFitChecker;

const BASE_RESIZE_DELTA: Size = { height: 0, width: 0 };
const RESIZE_THROTLE_MS = 2.5;

const useBoxSizeFitChecker = (
  config: UseBoxSizeFitCheckerConfig,
): UseBoxSizeFitChecker => {
  const {
    baseTextValue,
    draftEditorData,
    extensionConfig = {},
    initialEditorData,
  } = config;

  const {
    enabled = false,
    onTextRangeChange,
    onToggleRechunkRequired,
    rechunkAlert,
    submitDisabledOnNoFit,
  } = extensionConfig;

  const [adjustedTextValue, setAdjustedTextValue] = React.useState(
    baseTextValue,
  );

  const handleAdjustTextValue: TextOverlayResizeObserver = React.useMemo(
    () =>
      throttle((updatedDelta = BASE_RESIZE_DELTA, opts): void => {
        if (!enabled || opts?.type === 'stop') {
          return;
        }

        const [textLineStartPost, textLineEndPost] = getBoxMaxLines(
          baseTextValue,
          draftEditorData,
          updatedDelta,
        );

        const nextAdjustedText = baseTextValue
          .substring(textLineStartPost, textLineEndPost)
          .trim();

        setAdjustedTextValue(nextAdjustedText);
      }, RESIZE_THROTLE_MS),
    [baseTextValue, draftEditorData, enabled],
  );

  React.useEffect(() => {
    requestAnimationFrame(() => {
      handleAdjustTextValue();
    });
  }, [handleAdjustTextValue, draftEditorData]);

  React.useEffect(() => {
    if (!enabled) {
      setAdjustedTextValue(baseTextValue);
    }
  }, [baseTextValue, enabled]);

  const { submitDisabled, submitDisabledMessage } = React.useMemo(() => {
    const disabled = shouldDisableSubmitButton(
      adjustedTextValue,
      submitDisabledOnNoFit?.enabled,
    );
    const message = disabled ? submitDisabledOnNoFit?.message : undefined;

    return {
      submitDisabled: disabled,
      submitDisabledMessage: message,
    };
  }, [adjustedTextValue, submitDisabledOnNoFit]);

  const { showRechunkAlert, rechunkAlertMessage } = React.useMemo(() => {
    const show = shouldShowRechunkAlert(
      initialEditorData,
      draftEditorData,
      rechunkAlert?.enabled,
    );
    const message = show ? rechunkAlert?.message : undefined;

    return {
      showRechunkAlert: show,
      rechunkAlertMessage: message,
    };
  }, [draftEditorData, initialEditorData, rechunkAlert]);

  React.useEffect(() => {
    onTextRangeChange?.([0, adjustedTextValue.length]);
  }, [adjustedTextValue, onTextRangeChange]);

  React.useEffect(() => {
    onToggleRechunkRequired?.(showRechunkAlert);
  }, [onToggleRechunkRequired, showRechunkAlert]);

  // This effect is necessary because fonts might present a delay for being loaded.
  // In screen this can be seen as a small period of time with the font set to a serif
  // font and then for it to be switched to the real font face. For that reason when
  // the loadingdone event is fired, an extra redraw is done.
  useFontLoadedListener({
    onFontLoadingDone: React.useCallback(() => handleAdjustTextValue(), [handleAdjustTextValue])
  })

  return {
    resizeDeltaObserver: handleAdjustTextValue,
    rechunkAlertMessage,
    showRechunkAlert,
    submitDisabled,
    submitDisabledMessage,
    textValue: adjustedTextValue,
  };
};

export default useBoxSizeFitChecker;
