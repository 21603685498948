import { AnimationKey, AnimationModule } from '../types';
import jumpAnimationModule from './JumpAnimationModule';
import karaokeAnimationModule from './KaraokeAnimationModule';
import noAnimationModule from './NoAnimationModule';
import popAnimationModule from './PopAnimationModule';
import terminalAnimationModule from './TerminalAnimationModule';
import typewriterAnimationModule from './TypewriterAnimationModule';

const animationModules: Record<AnimationKey, AnimationModule> = {
  none: noAnimationModule,
  karaoke: karaokeAnimationModule,
  typewriter: typewriterAnimationModule,
  terminal: terminalAnimationModule,
  pop: popAnimationModule,
  jump: jumpAnimationModule,
};

export default animationModules;
