import { CaptionsAnimationConfig, CaptionsRendererConfig, } from '@sparemin/text-effects';
import { indexBy } from 'underscore';

import { createCaptionCueObject, getDefaultCaptionWordStyling } from 'utils/caption-adv-animation';
import { updateRgbaStringAlpha } from 'utils/color';

import { KARAOKE_ANIMATION_NAMES } from './constants';
import { AnimationConfigGetterParams, AnimationKey, AnimationModule } from './types';

export const checkIsKaraokeAnimation = (
  selectedAdvancedAnimation: CaptionsAnimationConfig,
): boolean => {
  return KARAOKE_ANIMATION_NAMES.includes(
    selectedAdvancedAnimation?.animationName,
  );
};

export const getAnimationChildView = (
  selectedAdvancedAnimation: CaptionsAnimationConfig,
  animationModules: AnimationModule[],
): React.ReactNode => {
  if (!selectedAdvancedAnimation?.enabled) {
    return null;
  }

  const animationModulesAsObject = indexBy(animationModules, 'key') as Record<
    AnimationKey,
    AnimationModule
  >;

  if (checkIsKaraokeAnimation(selectedAdvancedAnimation)) {
    return animationModulesAsObject?.karaoke?.contents;
  }

  return null;
};

export const getAdvancedAnimationPreviewConfig = (config: AnimationConfigGetterParams, paused?: boolean): CaptionsRendererConfig => {
  const { advancedAnimation, canvasSize, textStyles, textValue } = config;

  return {
    cues: advancedAnimation?.enabled
        ? [createCaptionCueObject(textValue)]
        : [],
        textStyles,
        animationConfig: advancedAnimation?.enabled ? {
          ...advancedAnimation,
          enabled: paused ? false : advancedAnimation?.enabled,
          properties: {
            ...advancedAnimation.properties,
            wordActiveColor:
            advancedAnimation.animationName !== 'activeWordColor'
                ? textStyles?.color
                : advancedAnimation.properties?.wordEmphasisColor,
            wordFutureColor: updateRgbaStringAlpha(textStyles?.color, 0.5),
            wordPastColor: textStyles?.color,
          },
        } : undefined,
        ...getDefaultCaptionWordStyling(canvasSize?.width),
  }
}
