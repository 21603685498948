import { Spacer } from '@sparemin/blockhead';
import { GOOEY_TEXT_UNSUPPORTED_CAPTION_ANIMATION_NAMES } from '@sparemin/text-effects';
import React from 'react';

import TextToolbar from 'components/TextToolbar';

import useTextOverlayAdvancedAnimation from '../../extensions/AnimationsTab/useTextOverlayAdvancedAnimation';
import useTextOverlayEffects from '../../hooks/useTextOverlayEffects';
import {
  block,
  DEFAULT_HIGHLIGHT_VALUE,
  DEFAULT_OUTLINE_VALUE,
  DEFAULT_SHADOW_VALUE,
} from '../../utils';

const TextOverlayEffectsToolbar: React.FunctionComponent = () => {
  const { selectedAdvancedAnimation } = useTextOverlayAdvancedAnimation();

  const {
    onChangeHighlightStyle,
    onChangeShadowStyle,
    onChangeOutlineStyle,
    selectedTextHighlightStyle,
    selectedShadowStyle,
    selectedOutlineStyle,
  } = useTextOverlayEffects();

  return (
    <Spacer
      orientation="vertical"
      space="20px"
      align="center"
      justify="space-between"
    >
      <div className={block('toolbar-row')}>
        <TextToolbar.TextHighlightPickerV2
          defaultValue={DEFAULT_HIGHLIGHT_VALUE}
          onChange={onChangeHighlightStyle}
          value={selectedTextHighlightStyle}
          disableControls={GOOEY_TEXT_UNSUPPORTED_CAPTION_ANIMATION_NAMES.includes(
            selectedAdvancedAnimation.animationName,
          )}
        />
        <TextToolbar.TextShadowInputV2
          defaultValue={DEFAULT_SHADOW_VALUE}
          onChange={onChangeShadowStyle}
          value={selectedShadowStyle}
        />
      </div>

      <div className={block('toolbar-row')}>
        <TextToolbar.OutlineInput
          value={selectedOutlineStyle}
          defaultValue={DEFAULT_OUTLINE_VALUE}
          onChange={onChangeOutlineStyle}
        />
      </div>
    </Spacer>
  );
};

export default TextOverlayEffectsToolbar;
