import * as React from 'react';

import { AutogramVideoType } from 'types';
import CaptionsAsset from '../assets/CaptionsAsset';
import { useCanvasSize } from '../context/CanvasSizeContext';
import { useTemplateState } from '../context/VideoTemplateStateContext';
import Layer from './Layer';

export interface CaptionsLayerProps {
  layerId?: string;
  videoType?: AutogramVideoType;
}

const CaptionsLayer: React.FC<CaptionsLayerProps> = () => {
  const { captions } = useTemplateState();
  const { canvas } = useCanvasSize();

  if (!captions || canvas.height === undefined || canvas.width === undefined) {
    return null;
  }

  return (
    <Layer>
      <CaptionsAsset captionsOverride={captions} />
    </Layer>
  );
};

export default CaptionsLayer;
