import { Cursor } from '@sparemin/blockhead';
import React from 'react';
import { AnimationModule } from '../../types';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import AnimationModuleButton from '../AnimationModuleButton';

export interface TypewriterAnimationModuleProps {}

const TypewriterAnimation: React.FC<TypewriterAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnOnAdvancedAnimation,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="Typewriter"
      icon={<Cursor width={50} height={50} />}
      isSelected={
        selectedAdvancedAnimation?.enabled &&
        selectedAdvancedAnimation?.animationName === 'typewriter'
      }
      onPress={() => onTurnOnAdvancedAnimation('typewriter')}
    />
  );
};

const typewriterAnimationModule: AnimationModule = {
  key: 'typewriter',
  name: 'Typewriter',
  control: TypewriterAnimation,
};

export default typewriterAnimationModule;
