import { Spacer, Text } from '@sparemin/blockhead';
import React from 'react';
import { block } from '../../utils';

export interface KaraokeAnimationControlContainerProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

const KaraokeAnimationControlContainer: React.FC<KaraokeAnimationControlContainerProps> = ({
  title,
  children,
}) => {
  return (
    <Spacer
      orientation="horizontal"
      align="center"
      justify="space-between"
      className={block('animation-control-container')}
    >
      <Text variant="p" className={block('control-label')}>
        {title}
      </Text>

      {children}
    </Spacer>
  );
};

export default KaraokeAnimationControlContainer;
