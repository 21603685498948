import {
  CaptionsAnimationConfig,
  CaptionsAnimationName,
} from '@sparemin/text-effects';
import { fromJS } from 'immutable';
import { useCallback } from 'react';
import { useTextOverlay } from '../../state/TextOverlayProvider';
import { TextOverlayState } from '../../state/types';

export interface UseTextOverlayAdvancedAnimation
  extends Pick<TextOverlayState, 'onTurnOnAdvancedAnimation'> {
  selectedAdvancedAnimation: CaptionsAnimationConfig | undefined;
  onActiveAnimationChange: (animationName: CaptionsAnimationName) => void;
  onWordEmphasisColorChange: (color: string) => void;
  onTurnAnimationsOff: () => void;
}

const useTextOverlayAdvancedAnimation = (): UseTextOverlayAdvancedAnimation => {
  const {
    draftEditorData,
    onUpdateOverlay,
    onTurnOnAdvancedAnimation,
  } = useTextOverlay();

  const selectedAdvancedAnimation = draftEditorData
    .get('advancedAnimation')
    ?.toJS();

  const handleActiveAnimationChange = useCallback(
    (animationName: CaptionsAnimationName): void => {
      onUpdateOverlay(advancedAnimation => {
        return advancedAnimation.set(
          'advancedAnimation',
          fromJS({
            ...selectedAdvancedAnimation,
            animationName,
          }),
        );
      });
    },
    [onUpdateOverlay, selectedAdvancedAnimation],
  );

  const handleWordEmphasisColorChange = useCallback(
    (color: string): void => {
      onUpdateOverlay(advancedAnimation => {
        return advancedAnimation.set(
          'advancedAnimation',
          fromJS({
            ...selectedAdvancedAnimation,
            properties: {
              ...selectedAdvancedAnimation?.properties,
              wordEmphasisColor: color,
            },
          }),
        );
      });
    },
    [onUpdateOverlay, selectedAdvancedAnimation],
  );

  const handleTurnAnimationsOff = useCallback((): void => {
    onUpdateOverlay(advancedAnimation => {
      return advancedAnimation.set(
        'advancedAnimation',
        fromJS({
          enabled: false,
        }),
      );
    });
  }, [onUpdateOverlay]);

  return {
    selectedAdvancedAnimation,
    onTurnOnAdvancedAnimation,
    onActiveAnimationChange: handleActiveAnimationChange,
    onWordEmphasisColorChange: handleWordEmphasisColorChange,
    onTurnAnimationsOff: handleTurnAnimationsOff,
  };
};

export default useTextOverlayAdvancedAnimation;
