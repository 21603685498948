import { ICaptions } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';

import { BASE_CAPTION_FULL_EP_OVERRIDES_MAP, BASE_CAPTION_OVERRIDES_MAP } from './constants';

export const getBaseCaptionsOverride = (aspectRatio: number, isFullEpisode?: boolean): ICaptions => {
  try {
    const aspectRatioName = getAspectRatioName(aspectRatio);
    return isFullEpisode ?
      BASE_CAPTION_FULL_EP_OVERRIDES_MAP[aspectRatioName] :
      BASE_CAPTION_OVERRIDES_MAP[aspectRatioName];
  } catch {
    return undefined;
  }
};

export const isCaptionsAnimationsTabDisabled = (
  isFullEpisode?: boolean,
) => {
  return isFullEpisode;
};
