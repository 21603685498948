import {
  HighlighterSolid,
  Radius,
  Spacer,
  TooltipContainerProps,
} from '@sparemin/blockhead';
import {
  GOOEY_TEXT_MAX_RADIUS_VALUE,
  GOOEY_TEXT_MIN_RADIUS_VALUE,
} from '@sparemin/text-effects';
import React from 'react';

import useStyleToggle from '../hooks/useStyleToggle';
import { TextColorPicker, TextSlider } from '../TextControls';
import ToolbarPopoverButton from '../ToolbarPopoverButton';
import { textControlsBlock as block } from '../utils';
import { TextHighlight } from './types';

type PickedTooltipContainerProps = Pick<
  TooltipContainerProps,
  'offset' | 'placement' | 'strategy'
>;

interface TextHighlightPickerProps extends PickedTooltipContainerProps {
  defaultValue: TextHighlight;
  disableControls?: boolean;
  onChange: (value: TextHighlight) => void;
  value?: TextHighlight;
}

const TextHighlightPicker: React.FunctionComponent<TextHighlightPickerProps> = props => {
  const {
    defaultValue,
    offset = 8,
    onChange,
    placement = 'top',
    strategy = 'fixed',
    value,
    disableControls = false,
  } = props;

  const { onToggle, isEnabled } = useStyleToggle({
    defaultValue,
    onChange,
    value,
  });

  const handleClickButton = React.useCallback(() => {
    onToggle();
  }, [onToggle]);

  const handleChangeColorStyle = React.useCallback(
    (color: string): void => {
      onChange({
        ...value,
        color,
      });
    },
    [onChange, value],
  );

  const handleChangeRadiusStyle = React.useCallback(
    (radius: number): void => {
      onChange({
        ...value,
        radius,
      });
    },
    [onChange, value],
  );

  const Icon = (
    <HighlighterSolid
      className={block('icon', { 'style-off': !isEnabled })}
      color={isEnabled ? value?.color : 'transparent'}
    />
  );

  return (
    <ToolbarPopoverButton
      isEnabled={isEnabled}
      disableControls={disableControls}
      offset={offset}
      onClickTrigger={handleClickButton}
      placement={placement}
      popoverContent={
        <Spacer
          className={block('controls-popover-content')}
          orientation="vertical"
          space={1}
        >
          <TextColorPicker
            onChange={handleChangeColorStyle}
            value={value?.color}
          />
          <TextSlider
            icon={<Radius height={20} width={20} />}
            label="Round corners"
            maxValue={GOOEY_TEXT_MAX_RADIUS_VALUE}
            minValue={GOOEY_TEXT_MIN_RADIUS_VALUE}
            onChange={handleChangeRadiusStyle}
            value={value?.radius}
          />
        </Spacer>
      }
      strategy={strategy}
    >
      {Icon} Highlight
    </ToolbarPopoverButton>
  );
};

export default TextHighlightPicker;
