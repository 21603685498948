import { CaptionsAnimationConfig } from '@sparemin/text-effects';
import { AdvancedTextConfigs } from 'utils/text-templates/types';

export const DEFAULT_ADVANCED_TEXT_CONFIGS: AdvancedTextConfigs = {
  gooeyText: {
    color: 'rgba(0, 0, 0, 0.7)',
    radius: 0,
  },
};

export const DEFAULT_DISABLED_ADVANCED_ANIMATION_CONFIGS: CaptionsAnimationConfig = {
  enabled: false,
  animationName: null,
  properties: {
    wordActiveColor: 'rgba(255, 255, 255, 1)',
    wordEmphasisColor: '#19B5FE',
    wordFutureColor: 'rgba(255, 255, 255, 0.5)',
    wordPastColor: 'rgba(255, 255, 255, 1)',
  },
};

export const DEFAULT_ADVANCED_ANIMATION_CONFIGS: CaptionsAnimationConfig = {
  enabled: true,
  animationName: 'activeWordHighlight',
  properties: {
    wordActiveColor: 'rgba(255, 255, 255, 1)',
    wordEmphasisColor: '#19B5FE',
    wordFutureColor: 'rgba(255, 255, 255, 0.5)',
    wordPastColor: 'rgba(255, 255, 255, 1)',
  },
};

export const DEFAULT_OUTLINE = {
  color: 'rgba(0, 0, 0, 1)',
  thickness: 0.04,
};

export const DEFAULT_FULL_EP_OUTLINE = {
  color: 'rgba(0, 0, 0, 1)',
  thickness: 0.12
}

export const DEFAULT_TEXT_SHADOW = {
  blur: 5,
  color: "rgba(0, 0, 0, 1)",
  x: 0,
  y: 0
}

export const DEFAULT_SQUARE_CAPTIONS_OVERRIDES = {
  advancedTextConfigs: undefined,
  advancedAnimation: DEFAULT_ADVANCED_ANIMATION_CONFIGS,
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Oswald',
    fontSize: '6.578947368421052vw',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    textOutline: DEFAULT_OUTLINE,
    textTransform: 'uppercase',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 18,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 380, width: 380 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_SQUARE_FULL_EP_CAPTIONS_OVERRIDES = {
  advancedTextConfigs: DEFAULT_ADVANCED_TEXT_CONFIGS,
  advancedAnimation: DEFAULT_DISABLED_ADVANCED_ANIMATION_CONFIGS,
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Open Sans',
    fontSize: '3.9473684210526314vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    textOutline: DEFAULT_FULL_EP_OUTLINE,
    textShadow: DEFAULT_TEXT_SHADOW,
    textTransform: 'none',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 15,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 380, width: 380 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_PORTRAIT_CAPTIONS_OVERRIDES = {
  advancedTextConfigs: undefined,
  advancedAnimation: DEFAULT_ADVANCED_ANIMATION_CONFIGS,
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Oswald',
    fontSize: '10.330578512396695vw',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    textOutline: DEFAULT_OUTLINE,
    textTransform: 'uppercase',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 18,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 430.22, width: 242 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_PORTRAIT_FULL_EP_CAPTIONS_OVERRIDES = {
  advancedTextConfigs: DEFAULT_ADVANCED_TEXT_CONFIGS,
  advancedAnimation: DEFAULT_DISABLED_ADVANCED_ANIMATION_CONFIGS,
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Open Sans',
    fontSize: '6.198347107438017vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    textOutline: DEFAULT_FULL_EP_OUTLINE,
    textShadow: DEFAULT_TEXT_SHADOW,
    textTransform: 'none',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 15,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 430.22, width: 242 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_LANDSCAPE_CAPTIONS_OVERRIDES = {
  advancedTextConfigs: undefined,
  advancedAnimation: DEFAULT_ADVANCED_ANIMATION_CONFIGS,
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Oswald',
    fontSize: '3.2552083333333335vw',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    textOutline: DEFAULT_OUTLINE,
    textTransform: 'uppercase',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 18,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 432, width: 768 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const DEFAULT_LANDSCAPE_FULL_EP_CAPTIONS_OVERRIDES = {
  advancedTextConfigs: DEFAULT_ADVANCED_TEXT_CONFIGS,
  advancedAnimation: DEFAULT_DISABLED_ADVANCED_ANIMATION_CONFIGS,
  containerStyle: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Open Sans',
    fontSize: '1.953125vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.2,
    textAlign: 'center',
    textDecoration: 'none',
    textOutline: DEFAULT_FULL_EP_OUTLINE,
    textShadow: DEFAULT_TEXT_SHADOW,
    textTransform: 'none',
    width: '80vw',
  },
  editor: {
    styleContext: {
      fontSize: 15,
      textBoxHeight: 43.199999999999996,
      viewport: { height: 432, width: 768 },
    },
  },
  region: {
    type: 'absolute',
    properties: { top: '75vh', left: '10vw' },
  },
  textBoxHeight: 43.199999999999996,
  textStyle: {
    boxDecorationBreak: 'clone',
    paddingLeft: '0',
    paddingRight: '0',
    WebkitBoxDecorationBreak: 'clone',
  },
};

export const BASE_CAPTION_OVERRIDES_MAP = {
  landscape: DEFAULT_LANDSCAPE_CAPTIONS_OVERRIDES,
  portrait: DEFAULT_PORTRAIT_CAPTIONS_OVERRIDES,
  square: DEFAULT_SQUARE_CAPTIONS_OVERRIDES,
};

export const BASE_CAPTION_FULL_EP_OVERRIDES_MAP = {
  landscape: DEFAULT_LANDSCAPE_FULL_EP_CAPTIONS_OVERRIDES,
  portrait: DEFAULT_PORTRAIT_FULL_EP_CAPTIONS_OVERRIDES,
  square: DEFAULT_SQUARE_FULL_EP_CAPTIONS_OVERRIDES,
};
