import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, noop, omit, pick } from 'underscore';
import { DEFAULT_LANGUAGE } from 'blocks/AutomationWorkflowWizard/containers/AutomationWorkflowWizard/constants';
import { PodcastEpisodeData } from 'blocks/PodcastSearch/types';
import { H2 } from 'components/Heading';
import { OnStyleChange } from 'components/VideoTemplateEditor/useOnStyleChange';
import Wizard, { IndexedStep } from 'components/Wizard';
import usePodcastIdModal from 'containers/PodcastIdModal/usePodcastIdModal';
import useEddyPromoCheck from 'hooks/useEddyTranscriptionCheck';
import useLastUsedStyleDispatch from 'hooks/useLastUsedStyleDispatch';
import useLastUsedStyleSelector from 'hooks/useLastUsedStyleSelector';
import useOnMount from 'hooks/useOnMount';
import { fetchEddySupportedProjectLanguages } from 'redux/middleware/api/headliner-user-service';
import { hasLockedCustomizationSelector } from 'redux/modules/display-pref/selectors';
import { onAddEpisodeImage, onWizardNext } from 'redux/modules/mixpanel';
import { Dispatch } from 'redux/types';
import {
  AddAudioMeta,
  AspectRatioName,
  AudioSourceType,
  FrameSize,
  PodcastIdentifier,
  TemplateType,
  VideoTypes,
} from 'types';
import { getAspectRatio, getAspectRatioValue } from 'utils/aspect-ratio';
import { getDuration } from 'utils/audio';
import { BLANK_CANVAS_TEMPLATE_ID } from 'utils/constants';
import { FatalError } from 'utils/FatalError';
import { isBlankCanvas } from 'utils/templates';
import { millisToSec } from 'utils/time';
import AddPodcastStep from '../AddPodcastStep';
import { CustomizeStepProps } from '../CustomizeStep';
import { CustomizeStepView } from '../CustomizeStep/CustomizeStep';
import LanguageStep from '../LanguageStep';
import { SourceStepType } from '../SourceStep/types';
import useSelectAudioTab from '../useSelectAudioTab';
import EpisodeAddAudioStep from './EpisodeAddAudioStep';
import EpisodeWizardCustomizeStep from './EpisodeWizardCustomizeStep';
import EpisodeWizardExportVideoStep from './EpisodeWizardExportVideoStep';
import EpisodeWizardProgressStep from './EpisodeWizardProgressStep';
import EpisodeWizardSourceStep from './EpisodeWizardSourceStep';
import { EpisodeWizardProps, Step, Steps } from './types';
import useEpisodeWizardBlocker from './useEpisodeWizardBlocker';
import useEpisodeWizardQueryParams from './useEpisodeWizardQueryParams';
import { block, getStepIndex } from './utils';

const { useCallback, useEffect, useRef, useState } = React;

// tslint:disable-next-line variable-name
const EpisodeWizard: React.FC<EpisodeWizardProps> = ({
  defaultTranscription,
  fullEpisodeCaptionEnabled,
  onCancel,
  onError,
  onMount,
  onUnmount,
  onStepChange,
  onSubmit,
}) => {
  // NOTE - defaults from query params should override other defaults (i.e.
  // last used style)
  const { defaultAspectRatio } = useEpisodeWizardQueryParams();
  const { setLastUsedStyle } = useLastUsedStyleDispatch('fullEpisode');
  const { lastUsedStyle } = useLastUsedStyleSelector('fullEpisode');

  const { getDefaultPodcastFeedId } = usePodcastIdModal();
  const [aspectRatioName, setAspectRatioName] = useState<AspectRatioName>(
    defaultAspectRatio || lastUsedStyle.get('aspectRatioName'),
  );
  const aspectRatioValue = getAspectRatioValue(aspectRatioName);
  const [step, setStep] = useState<Step>();
  const dispatch = useDispatch<Dispatch>();
  const skipTemplateSelection = useSelector(hasLockedCustomizationSelector);
  const [templateId, setTemplateId] = useState<string>();
  const [customizeStepView, setCustomizeStepView] = useState<CustomizeStepView>(
    'templates',
  );
  const wizardRef = useRef<Wizard>();
  const audioSourceTypeRef = useRef<AudioSourceType>(null);
  const originalAudioUrlRef = useRef<string>();
  const [addAudioTab, setAddAudioTab] = useSelectAudioTab();
  const [audioSource, setAudioSource] = useState<File | string>(undefined);
  const [podcastIdentifier, setPodcastIdentifier] = useState<
    PodcastIdentifier
  >();

  const [audioLanguage, setAudioLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const [audioClipDurationSec, setAudioClipDurationSec] = useState<number>(
    undefined,
  );
  const [shouldGenerateAssets, setShouldGenerateAssets] = useState<
    boolean | undefined
  >();
  const [transcription, setTranscription] = useState(defaultTranscription);

  const { episodeWizard } = useEddyPromoCheck();

  useOnMount(onMount, onUnmount);

  const confirm = useEpisodeWizardBlocker(step);

  const handleStepChange = useCallback(
    (toStep: IndexedStep<Step>, fromStep: IndexedStep<Step>) => {
      setStep(toStep.stepId);
      if (
        toStep.index <=
        getStepIndex(Steps.UPLOAD_AUDIO_STEP, skipTemplateSelection)
      ) {
        setAudioSource(undefined);
      }
      onStepChange(toStep, fromStep, {
        templateId,
        audioSource: audioSourceTypeRef.current,
      });
    },
    [skipTemplateSelection, onStepChange, templateId],
  );

  const handleAudioAdded = useCallback(
    async (source, type: AudioSourceType, meta: AddAudioMeta) => {
      if (!source) return;

      const durationSec = await getDuration(source);

      setAudioClipDurationSec(durationSec);
      audioSourceTypeRef.current = type;
      setAudioSource(source);
      originalAudioUrlRef.current = meta?.originalAudioUrl;

      if (meta?.podcastId || meta?.episodeId) {
        setPodcastIdentifier(pick(meta, 'podcastId', 'episodeId'));
      }

      if (!meta) {
        const podcastId = await getDefaultPodcastFeedId();

        setPodcastIdentifier({ podcastId, episodeId: undefined });
      }

      wizardRef.current.jump('language');
    },
    [getDefaultPodcastFeedId],
  );

  const handleOnPodcastClick = useCallback((podcastId: string) => {
    audioSourceTypeRef.current = 'podcast';

    setPodcastIdentifier({
      podcastId,
      episodeId: undefined,
    });

    wizardRef.current.next();
  }, []);

  const handleOnEpisodeClick = useCallback((episode: PodcastEpisodeData) => {
    setAudioClipDurationSec(millisToSec(episode.durationMillis));
  }, []);

  const handleAspectRatioSelect = useCallback(
    (ratioName: AspectRatioName) => {
      setAspectRatioName(ratioName);
      setLastUsedStyle({ aspectRatioName: ratioName });
    },
    [setLastUsedStyle],
  );
  const handleCreateVideo = useCallback(
    async ({ projectName }, config, frameSizeOverride?: FrameSize) => {
      try {
        const submission = onSubmit({
          ...config,
          audioSource,
          audioSourceType: audioSourceTypeRef.current,
          originalAudioUrl: originalAudioUrlRef.current,
          podcastIdentifier,
          projectName,
          // it might be clearer to caller that blank project was selected if
          // there's no template id.  it avoids arguably unnecessary helper functions.
          // storing "blank" in the component state indicates that a choice was made,
          // whereas storing undefined makes that a little less clear
          templateId: isBlankCanvas(templateId) ? undefined : templateId,
          aspectRatio: getAspectRatio(aspectRatioName)?.toJS(),
          frameSizeOverride,
          shouldGenerateAssets,
          audioLanguage,
          transcription,
        });

        wizardRef.current.jump('submitting');
        await submission;
      } catch (err) {
        onError(new FatalError(err.message));
      }
    },
    [
      aspectRatioName,
      audioLanguage,
      audioSource,
      onError,
      onSubmit,
      podcastIdentifier,
      shouldGenerateAssets,
      templateId,
      transcription,
    ],
  );

  const handleCustomizeError: CustomizeStepProps['onError'] = useCallback(
    (error, _, meta) => {
      onError(error, meta);
    },
    [onError],
  );

  const handleStyleChange: OnStyleChange = useCallback(
    (style, actionType) => {
      if (actionType === 'IMAGE_ADD' || actionType === 'IMAGE_REPLACE') {
        dispatch(onAddEpisodeImage());
      } else if (actionType === 'TRANSCRIPTION_CHANGE') {
        setTranscription(s => ({
          ...s,
          ...style.transcription,
        }));
      } else {
        // "progress" and "image" are not tracked by last used style
        setLastUsedStyle(omit(style, 'progress', 'image', 'video'));
      }
    },
    [dispatch, setLastUsedStyle],
  );

  const handleSelectSourceStepType = useCallback(
    (selectedSourceStepType: SourceStepType): void => {
      dispatch(
        onWizardNext({
          step: 'Source',
          type: 'episode',
          path:
            selectedSourceStepType === 'BackCatalogUpsell'
              ? 'BackCatalogAutomation'
              : 'ManualCreation',
        }),
      );
      if (selectedSourceStepType === 'ManualCreation') {
        wizardRef.current.jump('uploadAudio');
      }
    },
    [dispatch],
  );

  const handleContinueFromLanguageStep = useCallback(
    (isLanguageSupported: boolean, generateAssets: boolean): void => {
      dispatch(
        onWizardNext({
          step: 'Language',
          type: 'episode',
          languageSelection: audioLanguage,
          eddyOptIn: isLanguageSupported
            ? generateAssets
              ? 'Yes'
              : 'No'
            : 'Disabled',
        }),
      );

      // As the episode wizard does not have a Language selector at the audio
      // clipper, the one from the transcript language step is used.
      setTranscription({
        ...transcription,
        language: audioLanguage,
      });

      wizardRef.current.jump('customize');
    },
    [audioLanguage, dispatch, transcription],
  );

  useEffect(() => onUnmount, [onUnmount]);

  useEffect(() => {
    setTemplateId(BLANK_CANVAS_TEMPLATE_ID[aspectRatioName]);
  }, [aspectRatioName]);

  useEffect(() => {
    dispatch(fetchEddySupportedProjectLanguages());
  }, [dispatch]);

  return (
    <Wizard
      ref={wizardRef}
      bodyClassName={block('body', {
        [step]: true,
        customize: step === 'customize',
      })}
      canBacktrack={step !== 'export' && step !== 'submitting'}
      className={block({ template: step === 'template' })}
      onCancelClick={onCancel}
      onStepChange={handleStepChange}
      onStepClick={confirm}
      steps={[
        {
          completedName: 'Source',
          component: (
            <EpisodeWizardSourceStep onClick={handleSelectSourceStepType} />
          ),
          name: 'Source',
          renderCancelButton: () => null,
          renderNextButton: () => null,
          stepId: 'source',
        },
        {
          completedName: 'Audio Selected',
          component: (
            <EpisodeAddAudioStep
              activeTab={addAudioTab}
              isFileSelected={!isUndefined(audioSource)}
              onAudioAdded={handleAudioAdded}
              onError={onError}
              onTabSelect={setAddAudioTab}
              onEpisodeClick={handleOnEpisodeClick}
            />
          ),
          name: 'Select Audio',
          renderCancelButton: () => null,
          renderNextButton: () => null,
          stepId: 'uploadAudio',
          showInNav: true,
        },
        {
          completedName: 'Audio Selected',
          showInNav: false,
          component: <AddPodcastStep onPodcastClick={handleOnPodcastClick} />,
          title: 'Select a podcast',
          description: (
            <H2 style={{ marginTop: '20px', marginBottom: '50px' }}>
              We start at the first episode and create full-length audiograms
              for three episodes a day. Once we catch <br /> up to the latest
              episode we’ll continue creating with each new release.
            </H2>
          ),
          name: 'Select Audio',
          renderCancelButton: () => null,
          renderNextButton: () => null,
          stepId: 'searchPodcast',
        },

        ...(episodeWizard.languageStepVisible
          ? [
              {
                stepId: 'language',
                name: 'Language',
                component: (
                  <LanguageStep
                    language={audioLanguage}
                    defaultShouldGenerateAssets={shouldGenerateAssets}
                    onLanguageChange={setAudioLanguage}
                    onShouldGenerateAssetsChange={setShouldGenerateAssets}
                    onContinue={handleContinueFromLanguageStep}
                    showEddyProjectCreationSelector={
                      episodeWizard.showEddyProjectCreationSelector
                    }
                  />
                ),
                renderCancelButton: () => null,
                renderNextButton: () => null,
              },
            ]
          : []),

        {
          component: (
            <EpisodeWizardCustomizeStep
              {...{
                lastUsedStyle,
                podcastIdentifier,
                skipTemplateSelection,
                templateId,
                audioSource,
                audioClipDurationSec,
                shouldGenerateAssets,
              }}
              fullEpisodeCaptionEnabled={fullEpisodeCaptionEnabled}
              language={audioLanguage}
              audioSourceType={audioSourceTypeRef.current}
              aspectRatio={aspectRatioValue}
              compatibilityTypes={[VideoTypes.FULL_EPISODE]}
              isProjectEditable
              onChangeView={setCustomizeStepView}
              onError={handleCustomizeError}
              onExportClick={handleCreateVideo}
              onSelectTemplate={setTemplateId}
              onSelectAspectRatio={handleAspectRatioSelect}
              onStyleChange={handleStyleChange}
              templateTypes={[
                TemplateType.HEADLINER_DEFAULT,
                TemplateType.USER_GENERATED,
                'blank',
              ]}
              transcription={transcription}
              view={customizeStepView}
            />
          ),
          name: 'Customize',
          renderCancelButton: () => null,
          renderNextButton: () => null,
          stepId: 'customize',
          showInNav: true,
          title: customizeStepView === 'templates' ? 'Choose a template' : null,
        },

        {
          component: (
            <EpisodeWizardProgressStep
              onCompleted={() => {
                wizardRef.current.next();
              }}
              onError={error => {
                onError(error);
              }}
            />
          ),
          name: 'submitting',
          animationEnabled: true,
          stepId: 'submitting',
          renderCancelButton: () => null,
          renderNextButton: () => null,
          showInNav: false,
        },
        {
          component: (
            <EpisodeWizardExportVideoStep
              source={audioSource}
              onError={error => {
                wizardRef?.current.jump('customize');
                onError(error);
              }}
            />
          ),
          name: 'export',
          animationEnabled: true,
          renderCancelButton: () => null,
          renderNextButton: () => null,
          showInNav: false,
          stepId: 'export',
        },
      ].filter(Boolean)}
    />
  );
};

EpisodeWizard.defaultProps = {
  onCancel: noop,
  onError: noop,
  onMount: noop,
  onStepChange: noop,
  onSubmit: noop,
  onUnmount: noop,
};

export default EpisodeWizard;
