import * as React from 'react';

import merge from 'utils/deepmerge';
import { DEFAULT_FEATURES } from '../state/constants';
import {
  VideoEditorState,
  VideoEditorStateContext,
  VideoTemplateEditorFeatures,
} from '../types';
import {
  DynamicImageIntegration,
  DynamicTextIntegration,
  DynamicVideoIntegration,
  EdgeVideosIntegration,
  MediaIntegration,
  StaticTextIntegration,
  TextIntegration,
  UploadMediaIntegration,
  VideoTemplateEditorIntegrations,
} from '../types/integrations';

const { useContext, useMemo } = React;

export interface VideoEditorStateProviderProps {
  children?: React.ReactNode;
  disabled?: boolean;
  features?: VideoTemplateEditorFeatures;
  integrations?: VideoTemplateEditorIntegrations;
  isFullEpisode?: boolean;
  loading?: boolean;
  state: VideoEditorState;
  addTextPlaceholder?: string;
  audioClipDurationSec?: number;
}

const DEFAULT_CLIP_DURATION_SEC = 600; // 10 minutes.

const StateContext = React.createContext<VideoEditorStateContext>(undefined);

export function useEditorState() {
  return useContext(StateContext);
}

export const VideoEditorStateProvider: React.FC<VideoEditorStateProviderProps> = ({
  addTextPlaceholder = 'Double click to add your text',
  audioClipDurationSec = DEFAULT_CLIP_DURATION_SEC,
  children,
  disabled,
  features,
  integrations,
  isFullEpisode,
  loading,
  state,
}) => {
  const { loadingIds, overlayOpen, selectedAsset } = state;

  const isLoading = loading || !loadingIds.isEmpty();

  const integrationInfo = useMemo(() => {
    const allIntegrations: VideoTemplateEditorIntegrations = integrations.sort(
      (i1, i2) => i1.priority - i2.priority,
    );

    const mediaIntegrations = allIntegrations.filter(
      i => i.type === 'media-source',
    ) as MediaIntegration[];

    const videoIntegrations = allIntegrations.filter(
      i => i.type === 'video',
    ) as MediaIntegration[];

    const dynamicImageIntegrations = mediaIntegrations.filter(
      i => i.creationType === 'dynamic',
    ) as DynamicImageIntegration[];

    const staticImageIntegrations = mediaIntegrations.filter(
      i => i.creationType === 'static',
    ) as UploadMediaIntegration[];

    const textIntegrations = allIntegrations.filter(
      i => i.type === 'text',
    ) as TextIntegration[];

    const dynamicTextIntegrations = textIntegrations.filter(
      i => i.creationType === 'dynamic',
    ) as DynamicTextIntegration[];

    const staticTextIntegrations = textIntegrations.filter(
      i => i.creationType === 'static',
    ) as StaticTextIntegration[];

    const edgeVideosIntegration = allIntegrations.filter(
      i => i.type === 'edge-videos-source',
    ) as EdgeVideosIntegration[];

    const dynamicVideoIntegrations = videoIntegrations.filter(
      i => i.creationType === 'dynamic',
    ) as DynamicVideoIntegration[];

    return {
      allIntegrations,
      dynamicImageIntegrations,
      dynamicTextIntegrations,
      edgeVideosIntegration,
      dynamicVideoIntegrations,
      mediaIntegrations,
      staticImageIntegrations,
      staticTextIntegrations,
      textIntegrations,
      supportsDynamicImages: dynamicImageIntegrations.length > 0,
      supportsDynamicText: dynamicTextIntegrations.length > 0,
      supportsStaticImages: staticImageIntegrations.length > 0,
      supportsStaticText: staticTextIntegrations.length > 0,
    };
  }, [integrations]);

  const value = useMemo(
    () => ({
      addTextPlaceholder,
      audioClipDurationSec,
      isFullEpisode,
      isLoading,
      loadingIds,
      overlayOpen,
      selectedAsset,
      disabled: disabled || isLoading,
      features: merge(DEFAULT_FEATURES, features),
      integrations: integrationInfo,
    }),
    [
      addTextPlaceholder,
      audioClipDurationSec,
      isFullEpisode,
      isLoading,
      loadingIds,
      overlayOpen,
      selectedAsset,
      disabled,
      features,
      integrationInfo,
    ],
  );

  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};
