import { Froggy } from '@sparemin/blockhead';
import React from 'react';
import { AnimationModule } from '../../types';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import AnimationModuleButton from '../AnimationModuleButton';

export interface JumpAnimationModuleProps {}

const JumpAnimation: React.FC<JumpAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnOnAdvancedAnimation,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="Jump"
      icon={<Froggy width={50} height={50} />}
      isSelected={
        selectedAdvancedAnimation?.enabled &&
        selectedAdvancedAnimation?.animationName === 'jump'
      }
      onPress={() => onTurnOnAdvancedAnimation('jump')}
    />
  );
};

const jumpAnimationModule: AnimationModule = {
  key: 'jump',
  name: 'Jump',
  control: JumpAnimation,
};

export default jumpAnimationModule;
