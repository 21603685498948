import {
  GooeyTextCanvasConfig,
  GooeyTextRenderer,
} from '@sparemin/text-effects';
import React from 'react';
import { throttle } from 'underscore';

import { Size } from 'types';

import { AdvancedTextConfigs } from 'utils/text-templates/types';

interface UseTextAssetEffectsConfig {
  advancedTextConfigs?: AdvancedTextConfigs;
  canvasConfig?: GooeyTextCanvasConfig;
  externalObservableData?: unknown;
}

interface UseTextAssetEffects {
  onResize: (size: Size) => void;
  wrapperNodeRef: React.MutableRefObject<HTMLDivElement>;
}

const RESIZE_LISTENER_THROTLE_MS = 5;

const useTextAssetEffects = (
  config: UseTextAssetEffectsConfig,
): UseTextAssetEffects => {
  const { advancedTextConfigs, canvasConfig, externalObservableData } = config;

  const gooeyEffectRendererRef = React.useRef<GooeyTextRenderer>();
  const wrapperNodeRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!wrapperNodeRef.current) {
      return;
    }

    gooeyEffectRendererRef.current = new GooeyTextRenderer(
      wrapperNodeRef.current,
      undefined,
      { canvasConfig },
    );
  }, [canvasConfig, wrapperNodeRef]);

  React.useEffect(() => {
    if (!gooeyEffectRendererRef.current) {
      return;
    }

    const gooeyTextConfig = advancedTextConfigs?.gooeyText;

    if (!gooeyTextConfig) {
      gooeyEffectRendererRef.current.clear();
    } else {
      requestAnimationFrame(() => {
        gooeyEffectRendererRef.current.redraw(gooeyTextConfig);
      });
    }
  }, [advancedTextConfigs, externalObservableData]);

  const handleResize = React.useMemo(() => throttle((): void => {
    if (!gooeyEffectRendererRef.current) {
      return;
    }

    const gooeyTextConfig = advancedTextConfigs?.gooeyText;

    if (gooeyTextConfig) {
      requestAnimationFrame(() => {
        gooeyEffectRendererRef.current.redraw(gooeyTextConfig);
      });
    }
  }, RESIZE_LISTENER_THROTLE_MS), [advancedTextConfigs?.gooeyText])

  return {
    onResize: handleResize,
    wrapperNodeRef,
  };
};

export default useTextAssetEffects;
