import React from 'react';

import { useCanvasSize } from 'components/VideoTemplateEditor/context/CanvasSizeContext';
import { CaptionsState } from 'components/VideoTemplateEditor/types';
import { Size } from 'types';
import { CAPTION_STYLE_FIT_TEXT } from 'utils/constants';

import useTextAssetEffects from '../../useTextAssetEffects';
import { RndAssetCallback } from '../RndAsset';
import useCaptionsAssetAdjust from './useCaptionsAssetAdjust';
import useCaptionsAssetAnimations from './useCaptionsAssetAnimations';

interface UseCaptionsAssetEffectsConfig {
  captionsOverride: CaptionsState;
  onDimensionsChange: RndAssetCallback;
}

interface UseCaptionsAssetEffects {
  animationsNodeRef: React.MutableRefObject<HTMLDivElement>;
  effectsNodeRef: React.MutableRefObject<HTMLDivElement>;
  isResizing: boolean;
  minimumSize: Size<number, number>
  onResize: (size: Size) => void;
  onResizeStart: () => void;
  onResizeStop: RndAssetCallback;
  truncatedTextValue: string;
}

const useCaptionsAssetEffects = (config: UseCaptionsAssetEffectsConfig): UseCaptionsAssetEffects => {
  const { captionsOverride, onDimensionsChange } = config;

  const { canvas } = useCanvasSize();

  const {
    minimumSize,
    onResize: assetAdjustResizeListener,
    onResizeStart,
    onResizeStop,
    truncatedTextValue,
  } = useCaptionsAssetAdjust({
    captionsState: captionsOverride,
    textValue: CAPTION_STYLE_FIT_TEXT,
  });

  const {
    onResize: effectsResizeListener,
    wrapperNodeRef: effectsNodeRef,
  } = useTextAssetEffects({
    advancedTextConfigs: captionsOverride?.advancedTextConfigs,
    externalObservableData: canvas,
  });

  const {
    isResizing: animationsIsResizing,
    onResizeEnd: animationResizeEndListener,
    onResizeStart: animationsResizeStartListener,
    wrapperNodeRef: animationsNodeRef,
  } = useCaptionsAssetAnimations({
    captionsContent: truncatedTextValue,
    captionsOverride,
    externalObservableData: canvas,
  });

  const handleResizeStart = React.useMemo(() => onResizeStart((): void => {
    animationsResizeStartListener()
  }), [animationsResizeStartListener, onResizeStart])

  const handleResizeStop = React.useMemo(() => onResizeStop((...args): void => {
    animationResizeEndListener()
    onDimensionsChange(...args);
  }), [animationResizeEndListener, onDimensionsChange, onResizeStop])

  const handleResize = React.useCallback((size: Size<number, number>): void => {
    assetAdjustResizeListener(size)
    effectsResizeListener(size);
  }, [assetAdjustResizeListener, effectsResizeListener])

  return {
    animationsNodeRef,
    effectsNodeRef,
    isResizing: animationsIsResizing,
    minimumSize,
    onResize: handleResize,
    onResizeStart: handleResizeStart,
    onResizeStop: handleResizeStop,
    truncatedTextValue,
  }
}

export default useCaptionsAssetEffects;
