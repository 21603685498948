import { flatten, has } from 'underscore';
import {
  isLegacyOverlayConvertAble,
  TextOverlayV2,
  validateCompatibleLegacyConfig,
  validateOverlayV2Integrity,
} from 'blocks/TextOverlayModal/v2';
import { AutogramVideoType, IEmbedConfig, Size } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import bem from 'utils/bem';
import { getRatio } from 'utils/numbers';
import { isAutogramActive, isFullEpisodeWizardActive } from 'utils/routes';
import {
  DYNAMIC_VIDEO_AUTOFRAME_INFOBOX_MESSAGE,
  DYNAMIC_VIDEO_AUTOFRAME_TOOLTIP_MESSAGE,
} from './constants';
import { DYNAMIC_ASSET_VALUE } from './integrations/useDynamicPodcastTextIntegration';
import {
  FramingMethod,
  TextOverlayExport,
  VideoTemplateStateContent,
} from './types';
import { TextIntegrationId } from './types/core';
import { UseMediaLayer } from './useMediaLayer';

export const block = bem('video-template-editor');
export const previewBlock = bem('video-template-editor-preview');
export const optionTileBlock = bem('video-template-editor-option');
export const optionsTemplateControls = bem(
  'video-template-editor-options-template-controls',
);
export const optionsBlock = bem('video-template-editor-options');
export const canvasBlock = bem('video-template-editor-canvas');
export const waveformViewBlock = bem('waveform-child-view');
export const imageViewBlock = bem('image-child-view');
export const progressViewBlock = bem('progress-child-view');
export const captionsViewBlock = bem('captions-child-view');
export const rndBlock = bem('video-template-editor-rnd');
export const ustPaneBlock = bem('video-template-editor-ust');
export const tileBlock = bem('video-template-editor-tile');
export const gridBlock = bem('video-template-editor-grid');
export const popperTileBlock = bem('popper-tile');
export const saveTemplatePopper = bem('save-template-popper');
export const progressPlacementTileBlock = bem('progress-placement-tile');
export const waveformPlacementPopperBlock = bem('waveform-placement-popper');
export const waveformSettingsTileBlock = bem('waveform-settings-tile');
export const textViewBlock = bem('text-child-view');
export const selectTileBlock = bem('video-template-editor-select-tile');
export const childViewCardBlock = bem('child-view-card');
export const textAssetModalBlock = bem('text-asset-modal');
export const timerPopperBlock = bem('timer-popper');
export const editorCanvasInfoboxBlock = bem('editor-canvas-infobox');
export const addImageModalBlock = bem('add-image-modal');
export const deleteDynamicImageModalBlock = bem('delete-dynamic-image-modal');
export const addImageTypeModalBlock = bem('add-image-type-modal');
export const addTextTypeModalBlock = bem('add-text-type-modal');
export const toggleCardBlock = bem('video-template-editor-toggle-card');
export const introOutroChildViewBlock = bem('intro-outro-child-view');
export const editorModalPreviewBlock = bem(
  'video-template-editor-modal-preview',
);

export const DEFAULT_END_MILLI = 10000;

export const DEFAULT_START_END_MILLI = {
  startMilli: 0,
  endMilli: DEFAULT_END_MILLI,
};

export const CONFIGURATION_PROPS_FOR_START_END_INIT_KEYS: Array<keyof IEmbedConfig> = [
  'soundwave',
  'slideshowInfo',
  'textOverlayInfo',
  'videoClips',
];

export const colorPickerSwatchClassName = (disabled?: boolean) =>
  block('color-picker-swatch', { disabled });

export function getCanvasDimensions(
  containerDimensions: Size<number>,
  canvasAspectRatio: number,
) {
  if (containerDimensions === undefined || canvasAspectRatio === undefined)
    return [];

  const {
    height: containerHeight,
    width: containerWidth,
  } = containerDimensions;
  const [canvasWidth, canvasHeight] = getRatio(canvasAspectRatio);

  const scale = Math.min(
    containerHeight / canvasHeight,
    containerWidth / canvasWidth,
  );

  return [canvasWidth * scale, canvasHeight * scale];
}

export function roundDimensions(dims: Size<number>) {
  if (!dims) return undefined;

  return {
    height: Math.round(dims.height),
    width: Math.round(dims.width),
  };
}

export function viewportToPct(value: any) {
  if (typeof value === 'string') {
    const units = value.slice(-2).toLowerCase();
    if (units !== 'vw' && units !== 'vh') return value;
    return `${parseFloat(value)}%`;
  }

  if (Object.prototype.toString.call(value) === '[object Object]') {
    return Object.keys(value).reduce((acc, key) => {
      acc[key] = viewportToPct(value[key]);
      return acc;
    }, {} as React.CSSProperties);
  }

  return value;
}

export function getObj(obj: { [k: string]: any }, ...path: string[]) {
  return path.reduce((current, seg) => {
    const val = current[seg];
    if (val) {
      return val;
    }
    current[seg] = {};
    return current[seg];
  }, obj);
}

/**
As UCS doesn't have time concept, this function allows the initialization
of the start and end time of the properties that need those values in order
to work properly. 
*/
export function initializeStartEndMilli(
  configuration: IEmbedConfig,
  prop: keyof IEmbedConfig,
) {
  const configProp = configuration[prop];
  if (!configProp) {
    return undefined;
  }

  if (Array.isArray(configProp)) {
    return configProp.map(current => ({
      ...current,
      ...DEFAULT_START_END_MILLI,
    }));
  }

  return Object.assign(configProp, { ...DEFAULT_START_END_MILLI });
}

/**
 * Replaces the text overlays with integration data with the default
 * dynamic assets values. This allows templates saved within UCS to be
 * used in other places by taking advantage of the dynamic assets
 * capabilities, like automatically replacing values and using dynamic
 * podcast inputs (podcast name and episode title).
 */
export function formatDynamicTextOverlays(
  textOverlays: TextOverlayExport,
): TextOverlayExport {
  if (!textOverlays) {
    return null;
  }

  return textOverlays.map(textOverlay => {
    const { integrationData } = textOverlay;

    if (!integrationData || integrationData.id === TextIntegrationId.STATIC)
      return textOverlay;

    const valueToReplace = DYNAMIC_ASSET_VALUE[integrationData.type];

    return {
      ...textOverlay,
      text: valueToReplace,
      textHtml: textOverlay.textHtml.replace(textOverlay.text, valueToReplace),
    };
  });
}

/**
 * It takes a state object and an assetId and returns the layerId of the asset.
 *
 * @param {VideoTemplateStateContent} state - VideoTemplateStateContent
 * @param {string} assetId - The assetId of the asset you want to get the layerId for.
 *
 * @returns The layerId of the asset.
 */
export function getLayerIdByAssetId(
  state: VideoTemplateStateContent,
  assetId: string,
): string {
  const layerIds = Object.keys(state)
    .map(key => {
      const element = state[key];

      // For cases where the layer id is in the root
      // of the object, e.g. soundwave.
      if (has(element, 'layerId') && assetId === key) {
        return element.layerId;
      }

      // For cases where the layer id is in the data
      // object and each key in that object represents an
      // asset id, e.g. textoverlays, videoclips etc.
      if (has(element, 'data') && element.data[assetId]) {
        return element.data[assetId].layerId;
      }

      return undefined;
    }, [])
    .filter(Boolean);

  return layerIds.length ? layerIds[0] : undefined;
}

export function getActiveLayers(state: VideoTemplateStateContent): string[] {
  const activeLayers = flatten(
    Object.keys(state)
      .map((key): string[] | undefined => {
        const currentConfig = state[key];

        // Some configurations (like soundwave) have the 'layerId'
        // prop at the root object.
        if (has(currentConfig, 'layerId')) {
          return currentConfig.layerId;
        }

        // Some configurations (like textOverlays, slideshow etc) have
        // the 'layerId' prop inside of the data object.
        if (has(currentConfig, 'data')) {
          const { data } = currentConfig;

          return Object.keys(data).map(dataKey => data[dataKey]?.layerId);
        }

        return undefined;
      })
      .filter(Boolean),
  );

  // The active layers list is not correctly sorted at this point. This should
  // filter the original layers order list and only keep the active values so that we
  // can get the active layers in the correct order.
  return state?.layers?.order.filter(id => activeLayers.indexOf(id) > -1);
}

/**
 * This function checks if validation is needed for error-prone scenarios based on the video type and
 * whether certain wizards are active.
 *
 * The validation for error-prone scenarios should occur when the Full Episode wizard is active or when
 * the Autogram wizard is active with a long-form automation (posting full episodes to YouTube or Facebook etc...).
 *
 * @param {AutogramVideoType} videoType - The current video type.
 *
 * @returns A boolean value indicating whether the validation should occur or not.
 */
export function checkShouldValidateTemplate(
  videoType?: AutogramVideoType,
): boolean {
  return (
    isFullEpisodeWizardActive() ||
    (isAutogramActive() && videoType === 'fullEpisode')
  );
}

/**
 * Checks if a given 'videoClip' layer is in the correct position within the list of layers.
 *
 * The 'videoClip' layer is considered to be in the correct position if it is at the beginning or at
 * the end of the list of layers.
 *
 * @param {string} layerId - The ID of the layer to be tested.
 * @param {string[]} layersList - The list of all the layers in the current configuration.
 * @param {number[]} validPositions - array indexes that are valid for 'videoClip' layers.
 * @returns A boolean value indicating whether the 'videoClip' layer is in the correct position or not.
 */
function checkClipIsCorrectPosition(
  layerId: string,
  layersList: string[],
  validPositions: number[],
): boolean {
  const currentLayerIndex = layersList.indexOf(layerId);

  return validPositions.some(position => position === currentLayerIndex);
}

/**
 * Checks if a given video template has a valid configuration for Full Episodes.
 *
 * A template is considered valid for Full Episode if the following conditions are met:
 * If captions are not enabled:
 * - Contains up to two 'videoClips' elements (videos or GIFs).
 * - The 'videoClip' element is at the beginning or at the end (or both in case
 * of multiple elements) of the layers list.
 * if Captions are enabled:
 * - contains at most one 'videoClips' element.
 * - The 'vidoeClip' element is at the end.
 *
 * @param {VideoTemplateStateContent} state - The template state object.
 *
 * @returns A boolean value indicating whether its a valid Full Episode template or not.
 */
export function checkIsValidFullEpisodeTemplate(
  state: VideoTemplateStateContent,
): boolean {
  const {
    videoClips: { order },
    transcription,
  } = state;

  const hasCaptions = transcription?.transcribe;

  const maxVideoClips = hasCaptions ? 1 : 2;

  const validLayerPositions = hasCaptions
    ? [getActiveLayers(state).length - 1]
    : [0, getActiveLayers(state).length - 1];

  if (order.length > maxVideoClips) {
    return false;
  }

  const videoClipsLayersIds = order.map(assetId =>
    getLayerIdByAssetId(state, assetId),
  );

  const isValidClipsConfig = videoClipsLayersIds
    .map(layer =>
      checkClipIsCorrectPosition(
        layer,
        getActiveLayers(state),
        validLayerPositions,
      ),
    )
    .every(Boolean);

  return isValidClipsConfig;
}

/**
 * Encapsulates the logic for validating if the default text asset modal
 * to show for a given overlay should default to the legacy modal and if
 * it should show the v2 compatibility message.
 * @param {TextOverlay | TextOverlayV2} textOverlay current text overlay to check
 * @returns {{ isLegacy: boolean, isV2CompatibilityMode: boolean}} Object indicating if the overlay should be handled by the legacy text editor and if the compatibility message should be shown.
 */
export const getTextOverlayEditorConfig = (
  textOverlay: TextOverlayV2,
): {
  isLegacy: boolean;
  isV2CompatibilityMode: boolean;
} => {
  const isV2Compatible = validateCompatibleLegacyConfig(textOverlay);
  const isV2CompatibilityMode =
    !validateOverlayV2Integrity(textOverlay) &&
    isLegacyOverlayConvertAble(textOverlay);
  const isV2 = validateOverlayV2Integrity(textOverlay);
  const isLegacy = !isV2Compatible || (!isV2 && !isV2CompatibilityMode);

  return {
    isLegacy,
    isV2CompatibilityMode,
  };
};

export const checkIsDynamicAutoframedVideoClipLayer = (
  mediaLayer: UseMediaLayer,
  framingMethod: FramingMethod,
  aspectRatio: number,
): boolean => {
  return (
    mediaLayer?.type === 'videoClip' &&
    mediaLayer?.asset?.integrationData?.id === 'dynamic' &&
    framingMethod === 'autoframe' &&
    getAspectRatioName(aspectRatio) !== 'landscape'
  );
};

export const getEditorCanvasInfoBoxMessage = (
  mediaLayer: UseMediaLayer,
  framingMethod: FramingMethod,
  aspectRatio: number,
): React.ReactNode => {
  if (
    checkIsDynamicAutoframedVideoClipLayer(
      mediaLayer,
      framingMethod,
      aspectRatio,
    )
  ) {
    return DYNAMIC_VIDEO_AUTOFRAME_INFOBOX_MESSAGE;
  }

  return undefined;
};

export const getEditorCanvasTooltipMessage = (
  mediaLayer: UseMediaLayer,
  framingMethod: FramingMethod,
  aspectRatio: number,
): string | undefined => {
  if (
    checkIsDynamicAutoframedVideoClipLayer(
      mediaLayer,
      framingMethod,
      aspectRatio,
    )
  ) {
    return DYNAMIC_VIDEO_AUTOFRAME_TOOLTIP_MESSAGE;
  }

  return undefined;
};

export const getMediaLayerFramingMethod = (
  mediaLayer: UseMediaLayer,
): FramingMethod | undefined => {
  if (
    mediaLayer?.type === 'videoClip' &&
    mediaLayer?.asset?.integrationData?.id === 'dynamic'
  ) {
    return mediaLayer?.asset?.framingMethod;
  }

  return undefined;
};
