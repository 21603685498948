import { Pop } from '@sparemin/blockhead';
import React from 'react';
import { AnimationModule } from '../../types';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import AnimationModuleButton from '../AnimationModuleButton';

export interface PopAnimationModuleProps {}

const PopAnimation: React.FC<PopAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnOnAdvancedAnimation,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="Pop"
      icon={<Pop width={50} height={50} />}
      isSelected={
        selectedAdvancedAnimation?.enabled &&
        selectedAdvancedAnimation?.animationName === 'pop'
      }
      onPress={() => onTurnOnAdvancedAnimation('pop')}
    />
  );
};

const popAnimationModule: AnimationModule = {
  key: 'pop',
  name: 'Pop',
  control: PopAnimation,
};

export default popAnimationModule;
