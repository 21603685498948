import React from 'react';

import { CaptionsState } from 'components/VideoTemplateEditor/types';

import { canvasBlock as block } from '../../utils';

interface CaptionsAssetContentProps {
  advancedAnimationsEnabled?: boolean;
  animationsNodeRef: React.MutableRefObject<HTMLDivElement>;
  effectsNodeRef: React.MutableRefObject<HTMLDivElement>;
  isResizing: boolean;
  onDoubleClick: () => void;
  textStyle: CaptionsState['textStyle']
  truncatedTextValue: string;
  wrapperStyle: React.CSSProperties;
}

const CaptionsAssetContent: React.FunctionComponent<CaptionsAssetContentProps> = (props) => {
  const { advancedAnimationsEnabled, animationsNodeRef, isResizing, onDoubleClick, textStyle, truncatedTextValue, effectsNodeRef, wrapperStyle } = props;

  // While resigin the box that displays the animations is momentarily
  // hidden and replaced with a box with just the truncated text for
  // the highlight (or any new effect) effect to be shown.
  // This is only applied when advanced animations are enabled. Otherwise,
  // just the regular box for the text effects will be shown.
  return (
    <div ref={effectsNodeRef}>
        <p
          className={block('captions-asset')}
          onDoubleClick={onDoubleClick}
          style={wrapperStyle}
        >
          <span
            style={{
              ...textStyle,
              // hide the animations box while resizing when animations are enabled
              display: isResizing && advancedAnimationsEnabled ? 'none' : 'inline',
            }}
            ref={animationsNodeRef}
          >
            {!advancedAnimationsEnabled && truncatedTextValue}
          </span>
          {advancedAnimationsEnabled && <span
            style={{
              ...textStyle,
              // Show the hidden texbox while resizing if the advanced animations are
              // enabled.
              // This node is hidden completely if advanced animations are not enabled.
              display: isResizing ? 'inline' : 'none',
            }}
          >
            {truncatedTextValue}
          </span>}
        </p>
      </div>
  )
}

export default CaptionsAssetContent;