import React from 'react';

import { CaptionsConfig } from 'blocks/TextOverlayModal/v2';
import { useEditorState } from 'components/VideoTemplateEditor/context/VideoEditorStateContext';

import { isCaptionsAnimationsTabDisabled } from 'utils/embed/captions';

import { useCanvasSize } from '../../context/CanvasSizeContext';
import { useNavigation } from '../../context/NavigationContext';
import { useTemplateDispatch } from '../../context/VideoTemplateDispatchContext';
import { useTemplateState } from '../../context/VideoTemplateStateContext';
import { useModalManager } from '../../ModalManager';
import { CaptionsState } from '../../types';
import RndAsset, { RndAssetCallback } from '../RndAsset';
import { getCaptionStyle } from '../utils';
import CaptionsAssetContent from './CaptionsAssetContent';
import useCaptionsAssetEffects from './useCaptionsAssetEffects';

interface CaptionsAssetProps {
  captionsOverride: CaptionsState;
}

const ASSET_ID = 'captions-asset';

const CaptionsAsset: React.FunctionComponent<CaptionsAssetProps> = props => {
  const { captionsOverride } = props;

  const [, send] = useNavigation();
  const { isFullEpisode } = useEditorState();
  const { transcription } = useTemplateState();
  const dispatch = useTemplateDispatch();
  const { toPx } = useCanvasSize();
  const modalManager = useModalManager();

  const trancribeEnabled = transcription?.transcribe;

  const captionsStyle = React.useMemo(() => {
    return getCaptionStyle(captionsOverride, toPx);
  }, [captionsOverride, toPx]);

    const handleDimensionsChange: RndAssetCallback = React.useCallback(
    value => {
      dispatch({
        type: 'CAPTIONS_DIMENSIONS_CHANGE',
        payload: {
          dimensions: value,
        },
      });
    },
    [dispatch],
  );

  const handleMouseDown = React.useCallback(() => {
    send({
      type: 'CHILD_VIEW_OPEN',
      payload: 'captions',
      meta: { source: 'preview' },
    });
  }, [send]);

  const handleDoubleClick = React.useCallback(() => {
    const showTabDisabled = isCaptionsAnimationsTabDisabled(
      isFullEpisode,
    );

    modalManager.showModal('edit-captions', {
      animationsTabEnabled: true,
      animationsTabDisplayConfig: {
        showTabDisabled,
        tabTooltip:
          showTabDisabled && 'Not supported for Full Episode',
      },
      captionsOverride,
      onSubmit: (captionsConfig: CaptionsConfig) => {
        dispatch({
          type: 'CAPTIONS_CONFIG_UPDATE',
          payload: {
            captions: captionsConfig,
          },
        });
      },
    });
  }, [captionsOverride, dispatch, isFullEpisode, modalManager]);

  const {
    animationsNodeRef,
    effectsNodeRef,
    isResizing,
    minimumSize,
    onResize,
    onResizeStart,
    onResizeStop,
    truncatedTextValue,
  } = useCaptionsAssetEffects({
    captionsOverride,
    onDimensionsChange: handleDimensionsChange,
  })

  if (!trancribeEnabled || !captionsStyle) {
    return null;
  }

  const { height, left, top, width, ...style } = captionsStyle;

  return (
    <RndAsset
      assetId="captions-asset"
      bounds="parent"
      enableResizing
      height={height}
      left={left}
      lockAspectRatio={false}
      minSizePx={minimumSize}
      onDragStop={handleDimensionsChange}
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onMouseDown={handleMouseDown}
      params={{ id: ASSET_ID }}
      resizeWhenBelowMin
      top={top}
      width={width}
    >
      <CaptionsAssetContent
        advancedAnimationsEnabled={captionsOverride?.advancedAnimation?.enabled}
        animationsNodeRef={animationsNodeRef}
        effectsNodeRef={effectsNodeRef}
        isResizing={isResizing}
        onDoubleClick={handleDoubleClick}
        textStyle={captionsOverride.textStyle}
        truncatedTextValue={truncatedTextValue}
        wrapperStyle={style}
      />
    </RndAsset>
  );
};

export default CaptionsAsset;
