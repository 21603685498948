import { Terminal } from '@sparemin/blockhead';
import React from 'react';
import { AnimationModule } from '../../types';
import useTextOverlayAdvancedAnimation from '../../useTextOverlayAdvancedAnimation';
import AnimationModuleButton from '../AnimationModuleButton';

export interface TerminalAnimationModuleProps {}

const TerminalAnimation: React.FC<TerminalAnimationModuleProps> = () => {
  const {
    selectedAdvancedAnimation,
    onTurnOnAdvancedAnimation,
  } = useTextOverlayAdvancedAnimation();

  return (
    <AnimationModuleButton
      label="Terminal"
      icon={<Terminal width={50} height={50} />}
      isSelected={
        selectedAdvancedAnimation?.enabled &&
        selectedAdvancedAnimation?.animationName === 'terminal'
      }
      onPress={() => onTurnOnAdvancedAnimation('terminal')}
    />
  );
};

const terminalAnimationModule: AnimationModule = {
  key: 'terminal',
  name: 'Terminal',
  control: TerminalAnimation,
};

export default terminalAnimationModule;
