import { CaptionCue, CaptionCueWord, CaptionsRendererConfig } from "@sparemin/text-effects";

export const DEFAULT_CAPTION_WORD_DURATION_MILLIS = 350;

export function createCaptionCueObject(
  text: string,
  wordDurationMillis: number = DEFAULT_CAPTION_WORD_DURATION_MILLIS,
): CaptionCue {
  const words = text.split(' ');
  const totalDuration = wordDurationMillis * words.length;

  let startMillis = 0;

  const wordsWithTimestamps: CaptionCueWord[] = words.map(word => {
    const endMillis = startMillis + wordDurationMillis;

    const wordObject: CaptionCueWord = {
      text: word,
      startMillis,
      endMillis,
    };

    startMillis = endMillis;

    return wordObject;
  });

  return {
    text,
    startMillis: 0,
    endMillis: totalDuration,
    words: wordsWithTimestamps,
  };
}

export function getDefaultCaptionWordStyling(
  canvasWidth: number,
): Pick<CaptionsRendererConfig, 'wordHighlightStyles' | 'wordUnderlineStyles'> {
  // borderRadius should be 1% of the canvas width.
  const borderRadius = `${canvasWidth / 100}px`;

  return {
    wordHighlightStyles: {
      borderRadius,
    },
    wordUnderlineStyles: {
      borderRadius,
    },
  };
}