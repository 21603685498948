import { CaptionsAnimationName } from '@sparemin/text-effects';

export const KARAOKE_ANIMATION_NAMES: CaptionsAnimationName[] = [
  'activeWordColor',
  'activeWordHighlight',
  'activeWordUnderline',
];

export const MAX_ANIMATIONS_TAB_WIDTH_PX: number = 546;

export const MAX_ANIMATIONS_TAB_TILES_PER_ROW_LARGE: number = 3;

export const MAX_ANIMATIONS_TAB_TILES_PER_ROW_SMALL: number = 2;
