import { Spacer } from '@sparemin/blockhead';
import React, { useCallback, useMemo, useState } from 'react';

import ResizeDetector from 'react-resize-detector';
import { useTextOverlay } from '../../state/TextOverlayProvider';

import { block } from '../../utils';
import {
  MAX_ANIMATIONS_TAB_TILES_PER_ROW_LARGE,
  MAX_ANIMATIONS_TAB_TILES_PER_ROW_SMALL,
  MAX_ANIMATIONS_TAB_WIDTH_PX,
} from './constants';
import { AnimationModule } from './types';
import useTextOverlayAdvancedAnimation from './useTextOverlayAdvancedAnimation';
import { checkIsKaraokeAnimation, getAnimationChildView } from './utils';

const AnimationsTab: React.FunctionComponent = () => {
  const { animationsTabExtension } = useTextOverlay();
  const { animationModules } = animationsTabExtension;
  const { selectedAdvancedAnimation } = useTextOverlayAdvancedAnimation();
  const [tabWidth, setTabWidth] = useState<number | undefined>();

  const animationChildView = getAnimationChildView(
    selectedAdvancedAnimation,
    animationModules,
  );

  // Divide animation modules into row groups based on the available tab width.
  const animationModulesRowGroups = useMemo((): AnimationModule[][] => {
    const maxTilesPerRow =
      !tabWidth || tabWidth > MAX_ANIMATIONS_TAB_WIDTH_PX
        ? MAX_ANIMATIONS_TAB_TILES_PER_ROW_LARGE
        : MAX_ANIMATIONS_TAB_TILES_PER_ROW_SMALL;
    const maxRows = Math.ceil(animationModules.length / maxTilesPerRow);

    return Array.from({
      length: maxRows,
    }).map((_, index) => {
      const position = index * maxTilesPerRow;

      return animationModules.slice(position, position + maxTilesPerRow);
    });
  }, [animationModules, tabWidth]);

  // Identify the active animation row to determine where to display the animation child view.
  const activeAnimationRow = useMemo((): number => {
    const animationName = checkIsKaraokeAnimation(selectedAdvancedAnimation)
      ? 'karaoke'
      : selectedAdvancedAnimation.animationName;

    return animationModulesRowGroups.findIndex(group =>
      group.find(animation => animation.key === animationName),
    );
  }, [animationModulesRowGroups, selectedAdvancedAnimation]);

  const handleResize = useCallback(
    (width: number): void => {
      setTabWidth(width);
    },
    [setTabWidth],
  );

  return (
    <ResizeDetector handleWidth onResize={handleResize}>
      <div className={block('animations-tab')}>
        <div>
          {animationModulesRowGroups.map((group, index) => (
            <div className={block('animations-tab-contents')}>
              {group.map(animationModule => (
                <animationModule.control key={animationModule.key} />
              ))}

              {activeAnimationRow === index && animationChildView && (
                <Spacer
                  orientation="horizontal"
                  space="24px"
                  justify="center"
                  className={block('animation-child-view')}
                >
                  {animationChildView}
                </Spacer>
              )}
            </div>
          ))}
        </div>
      </div>
    </ResizeDetector>
  );
};

export default AnimationsTab;
