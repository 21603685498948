import { IconButton, Link, PressEvent } from '@sparemin/blockhead';
import React from 'react';
import { block } from '../utils';

export interface ProjectRowActionButtonProps {
  icon?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  children?: React.ReactNode;
  onPress?: (e?: PressEvent) => void;
}

const ProjectRowActionButton: React.FC<ProjectRowActionButtonProps> = ({
  icon,
  disabled,
  href,
  children,
  onPress,
}) => {
  const commonProps = {
    className: block('action-button'),
    disabled,
    onPress: disabled ? undefined : onPress,
  };

  // This is a work around for this UX to work.
  // As stated: https://github.com/adobe/react-spectrum/issues/963, buttons
  // should not be used from within an anchor. That's why onPress does not exposes
  // preventDefault for the press event.
  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
    e => {
      e.preventDefault();
    },
    [],
  );

  return href ? (
    <Link {...commonProps} href={href} target="_blank">
      {icon}
      {children}
    </Link>
  ) : (
    <IconButton {...commonProps} onClick={handleButtonClick}>
      {icon}
      {children}
    </IconButton>
  );
};

export default ProjectRowActionButton;
