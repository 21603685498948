import { CaptionsRenderer } from '@sparemin/text-effects';
import React, { useCallback, useState } from 'react';
import { isEqual, throttle } from 'underscore';

import { AnimationConfigGetterParams, getAdvancedAnimationPreviewConfig } from '../extensions/AnimationsTab';
import { useTextOverlay } from '../state/TextOverlayProvider';
import { TextOverlayResizeObserver } from './useTextOverlayResizeDelta';

const STATE_UPDATER_THROTLE_MS = 5;

interface UseTextOverlayCaptionsAnimations {
  resizeDeltaObserver: TextOverlayResizeObserver
}

const useTextOverlayCaptionsAnimations = (): UseTextOverlayCaptionsAnimations => {
  const {
    canvasSize,
    draftEditorData,
    editorHtmlRef,
    textValue,
  } = useTextOverlay();

  const isResizingRef = React.useRef<boolean>(false)
  const captionsRendererRef = React.useRef<CaptionsRenderer | undefined>();

  const advancedAnimation = React.useMemo(
    () => draftEditorData.get('advancedAnimation')?.toJS(),
    [draftEditorData],
  );

  const textStyles = React.useMemo(
    () => draftEditorData.getIn(['editor', 'textStyle'])?.toJS(),
    [draftEditorData],
  );

  const pickedObservableConfig = React.useMemo(() => ({ advancedAnimation, canvasSize, textStyles, textValue }), [advancedAnimation, canvasSize, textStyles, textValue]);
  const [prevPickedObservableConfig, setPrevPickedObservableConfig] = useState<AnimationConfigGetterParams>(pickedObservableConfig)

  /**
   * Mount control, mounts the effect renderer when it has not been set yet.
   * It will skip if the animation renderer is already set.
   */
  React.useEffect(() => {
    const captionsContentElement = editorHtmlRef?.current?.querySelector(
      'span',
    );

    if (
      !captionsContentElement ||
      !pickedObservableConfig.advancedAnimation?.enabled ||
      captionsRendererRef?.current
    ) {
      return;
    }

    const config = getAdvancedAnimationPreviewConfig({
      advancedAnimation: pickedObservableConfig.advancedAnimation,
      canvasSize: pickedObservableConfig.canvasSize,
      textStyles: pickedObservableConfig.textStyles,
      textValue: pickedObservableConfig.textValue
    });

    captionsRendererRef.current = new CaptionsRenderer(captionsContentElement, config);

    requestAnimationFrame(() => {
      captionsRendererRef?.current?.getAnimator();
    });
  }, [editorHtmlRef, pickedObservableConfig]);

  /**
   * Controls the resizing action, it should be attached to the onResize listener
   * and turn the isResizing ref to true when the resizing starts and to false
   * when the resizing stops.
   */
  const onResize: TextOverlayResizeObserver = useCallback((_, opts) => {
    if(opts.type === 'start') {

      isResizingRef.current = true;
      captionsRendererRef.current?.getAnimator().reset?.();
      captionsRendererRef.current?.getAnimator().pause?.();
    } else if(opts.type === 'stop') {
      isResizingRef.current = false;
      requestAnimationFrame(() => {
        captionsRendererRef.current?.updateConfig(getAdvancedAnimationPreviewConfig({
          advancedAnimation: pickedObservableConfig.advancedAnimation,
          canvasSize: pickedObservableConfig.canvasSize,
          textStyles: pickedObservableConfig.textStyles,
          textValue: pickedObservableConfig.textValue
        }, isResizingRef.current));
      });
    }
  }, [pickedObservableConfig]);

  /**
   * It is necessary to throtle the config updater for improving perfomance and also
   * some race conditions that can produce a blinking due to the amount of consecutive
   * state updates.
   */
  const pickedConfigUpdater = React.useMemo(() => throttle((config: AnimationConfigGetterParams) => {
    setPrevPickedObservableConfig(config);

    requestAnimationFrame(() => {
      captionsRendererRef.current?.updateConfig(getAdvancedAnimationPreviewConfig({
        advancedAnimation: config.advancedAnimation,
        canvasSize: config.canvasSize,
        textStyles: config.textStyles,
        textValue: config.textValue
      }, isResizingRef.current));
    });
  }, STATE_UPDATER_THROTLE_MS), [])

  if(!isEqual(prevPickedObservableConfig, pickedObservableConfig)) {
    pickedConfigUpdater(pickedObservableConfig);
  }

  return {
    resizeDeltaObserver: onResize,
  };
};

export default useTextOverlayCaptionsAnimations;
