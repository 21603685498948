import React from 'react';

import { getCaptionsFromConfig } from 'components/VideoTemplateEditor/state/captions';
import { getBaseCaptionsOverride } from 'utils/embed/captions/utils';
import { useTemplateDispatch } from '../context/VideoTemplateDispatchContext';

interface UseCaptionsDefaultStyleConfig {
  aspectRatio: number;
  isFullEpisode: boolean;
}

const useCaptionsDefaultStyle = (config: UseCaptionsDefaultStyleConfig): void => {
  const { aspectRatio, isFullEpisode } = config;

  // Leaving the prev value as undefined to ensure at least it will be run once
  // even if isFullEpisode is set just once over the life cycle
  const [prevIsFullEpisode, setPrevIsFullEpisode] = React.useState<boolean | undefined>(undefined)

  const dispatch = useTemplateDispatch();

  if(prevIsFullEpisode !== isFullEpisode) {
    setPrevIsFullEpisode(isFullEpisode);
    const captionsConfig = getCaptionsFromConfig(getBaseCaptionsOverride(aspectRatio, isFullEpisode))

    dispatch({
      type: 'CAPTIONS_CONFIG_UPDATE',
      payload: {
        captions: captionsConfig,
      },
    })
  }

}

export default useCaptionsDefaultStyle;