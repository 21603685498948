import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { block } from '../../utils';
import KaraokeAnimationColorSelector from './KaraokeAnimationColorSelector';
import KaraokeAnimationEmphasisOptions from './KaraokeAnimationEmphasisOptions';

export interface KaraokeAnimationControlsProps {}

const KaraokeAnimationControls: React.FC<KaraokeAnimationControlsProps> = () => {
  return (
    <Spacer
      orientation="vertical"
      space="16px"
      align="center"
      justify="center"
      className={block('animation-controls-container')}
    >
      <KaraokeAnimationEmphasisOptions />

      <Spacer
        orientation="horizontal"
        space="16px"
        align="center"
        justify="center"
        className={block('extra-controls-container')}
      >
        <KaraokeAnimationColorSelector />
      </Spacer>
    </Spacer>
  );
};

export default KaraokeAnimationControls;
