import { CaptionsRenderer } from '@sparemin/text-effects';
import React from 'react';
import { isEqual, omit } from 'underscore';

import { Size } from 'types';
import { createCaptionCueObject, getDefaultCaptionWordStyling } from 'utils/caption-adv-animation';

import { useCanvasSize } from '../../context/CanvasSizeContext';
import { CaptionsState } from '../../types';

interface UseCaptionsAssetAnimationsConfig {
  captionsContent: string;
  captionsOverride?: CaptionsState;
  externalObservableData?: unknown;
}

interface UseCaptionsAssetAnimations {
  isResizing: boolean;
  onResizeStart: () => void;
  onResizeEnd: () => void;
  wrapperNodeRef: React.MutableRefObject<HTMLDivElement>;
}

const useCaptionsAssetAnimations = (
  config: UseCaptionsAssetAnimationsConfig,
): UseCaptionsAssetAnimations => {
  const { captionsContent, captionsOverride } = config;

  const pickedCaptionsOverride = omit(captionsOverride, ['size', 'position']);
  const [prevPickedCaptionOverride, setPrevPickedCaptionsOverride] = React.useState(pickedCaptionsOverride)

  const [isResizing, setIsResizing] = React.useState(false);

  const wrapperNodeRef = React.useRef<HTMLDivElement>(null);
  const captionsRendererRef = React.useRef<CaptionsRenderer | undefined>();

  const { canvas } = useCanvasSize();

  /**
   * Initializes the animation.
   */
  React.useEffect(() => {
    if (
      !wrapperNodeRef.current ||
      !captionsOverride?.advancedAnimation?.enabled ||
      captionsRendererRef.current
    ) {
      return;
    }

    captionsRendererRef.current = new CaptionsRenderer(wrapperNodeRef.current, {
      cues: [createCaptionCueObject(captionsContent)],
      textStyles: captionsOverride?.textStyle,
      animationConfig: captionsOverride?.advancedAnimation,
      ...getDefaultCaptionWordStyling(canvas?.width),
    });

    requestAnimationFrame(() => {
      captionsRendererRef?.current?.getAnimator();
    });
  }, [canvas, captionsContent, captionsOverride, isResizing]);

  const handleUpdateConfig = React.useCallback((
    nextCaptionsOverride: CaptionsState,
    nextCaptionsContent: string,
    nextCanvas?: Size<number>,
    skipConfig?: boolean,
  ): void => {
    requestAnimationFrame(() => {
      captionsRendererRef?.current?.updateConfig({
        cues: nextCaptionsOverride?.advancedAnimation.enabled ? [createCaptionCueObject(nextCaptionsContent)] : [],
        textStyles: nextCaptionsOverride?.textStyle,
        animationConfig: !skipConfig ? nextCaptionsOverride?.advancedAnimation : undefined,
        ...getDefaultCaptionWordStyling(nextCanvas?.width),
      });
    });
  }, []);

  if(!isEqual(prevPickedCaptionOverride, pickedCaptionsOverride)) {
    setPrevPickedCaptionsOverride(pickedCaptionsOverride);

    handleUpdateConfig(pickedCaptionsOverride, captionsContent, canvas);
  }

  /**
   * When the resizing starts the animation config is removed, this prevents
   * the animations consequent restarts with size updates to make the text
   * fit not to work properly. This can be seen mostly at the highlight not
   * matching the text properly.
   */
  const handleResizeStart = React.useCallback((): void => {
    setIsResizing(true);

    handleUpdateConfig(
      captionsOverride,
      captionsContent,
      canvas,
      true,
    )
  }, [canvas, captionsContent, captionsOverride, handleUpdateConfig])

  /**
   * When resize ends, the animations config is restored.
   */
  const handleResizeEnd = React.useCallback((): void => {
    setIsResizing(false);

    handleUpdateConfig(
      captionsOverride,
      captionsContent,
      canvas,
    );
  }, [canvas, captionsContent, captionsOverride, handleUpdateConfig])

  return {
    isResizing,
    onResizeEnd: handleResizeEnd,
    onResizeStart: handleResizeStart,
    wrapperNodeRef,
  };
};

export default useCaptionsAssetAnimations;
