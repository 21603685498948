import React from 'react';

interface UseFontLoadedListenerConfig {
  onFontLoadingDone: (evt: FontFaceSetLoadEvent) => void;
}

const useFontLoadedListener = (config: UseFontLoadedListenerConfig) => {
  const { onFontLoadingDone } = config;

  React.useEffect(() => {
    document.fonts.addEventListener('loadingdone', onFontLoadingDone);

    return () => {
      document.fonts.removeEventListener('loadingdone', onFontLoadingDone);
    };
  }, [onFontLoadingDone]);
}

export default useFontLoadedListener;