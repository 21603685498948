import React from 'react';
import { throttle } from 'underscore';

import { useCanvasSize } from 'components/VideoTemplateEditor/context/CanvasSizeContext';
import { Size } from 'types';

import { CaptionsState } from '../../types';
import { RndAssetCallback } from '../RndAsset';
import {
  getBoxMinimumHeight,
  getBoxTruncatedText,
  MINIMAL_WORD,
  MINIMUM_WIDTH,
} from './utils';

interface UseBoxSizeFitCheckerConfig {
  captionsState: CaptionsState;
  textValue: string;
}

interface UseBoxSizeFitChecker {
  minimumSize: Size<number>;
  onResize: (delta: Size<number>) => void;
  onResizeStart: (onResizeStart: () => void) => () => void;
  onResizeStop: (onResizeStop: RndAssetCallback) => RndAssetCallback;
  truncatedTextValue: string;
}

const RESIZE_THROTLE_MS = 5;
const DEFAULT_RESIZE_DELTA = { height: 0, width: 0 };

const useCaptionsAssetAdjust = (
  config: UseBoxSizeFitCheckerConfig,
): UseBoxSizeFitChecker => {
  const { captionsState, textValue } = config;

  const [resizeDelta, setResizeDelta] = React.useState(DEFAULT_RESIZE_DELTA);

  const isResizingRef = React.useRef(false);

  const { toPx } = useCanvasSize();

  const minimumSize = React.useMemo(() => {
    const minimumHeight = getBoxMinimumHeight(
      MINIMAL_WORD,
      captionsState,
      toPx,
    );
    return { height: minimumHeight, width: MINIMUM_WIDTH };
  }, [captionsState, toPx]);

  const truncatedTextValue = React.useMemo(() => {
    return getBoxTruncatedText(textValue, captionsState, resizeDelta, toPx);
  }, [captionsState, toPx, resizeDelta, textValue]);

  const handleResize = React.useMemo(
    () =>
      throttle((delta: Size<number>): void => {
        if (isResizingRef.current) {
          setResizeDelta(delta);
        }
      }, RESIZE_THROTLE_MS),
    [],
  );

  const handleResizeStart = React.useMemo(
    () => (onResizeStart: () => void) => (): void => {
      isResizingRef.current = true;
      onResizeStart();
    },
    [],
  );

  const handleResizeStop = React.useMemo(
    () => (onResizeStop: RndAssetCallback): RndAssetCallback => (
      ...args
    ): void => {
      isResizingRef.current = false;
      setResizeDelta(DEFAULT_RESIZE_DELTA);
      onResizeStop(...args);
    },
    [],
  );

  return {
    minimumSize,
    onResize: handleResize,
    onResizeStart: handleResizeStart,
    onResizeStop: handleResizeStop,
    truncatedTextValue,
  };
};

export default useCaptionsAssetAdjust;
